<template>
  <f-row>
    <f-col md="12">
      <f-input
        v-model="data.oldPassword"
        :label="$t('labels.oldPassword')"
        rules="required"
        type="password"
      />
    </f-col>
    <f-col md="12">
      <f-input
        v-model="data.newPassword"
        :label="$t('labels.newPassword')"
        rules="required|password:@confirmNewPassword"
        type="password"
      />
    </f-col>
    <f-col md="12">
      <f-input
        v-model="data.newPasswordAgain"
        :label="$t('labels.confirmNewPassword')"
        name="confirmNewPassword"
        rules="required"
        type="password"
      />
    </f-col>
  </f-row>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    }
  }
}
</script>
