<template>
  <div>
    <div class="page-header">
      <h4 class="page-title">
        {{ $t('labels.settings') }}
      </h4>
    </div>
    <f-card>
      <template slot="header-right">
        <f-button
          class="mr-2"
          icon="pencil"
          :label="$t('labels.updateMyInformation')"
          size="sm"
          variant="info"
          @click="updateModal"
        />
        <f-button
          class="mr-2"
          icon="key"
          :label="$t('labels.changePassword')"
          size="sm"
          variant="primary"
          @click="() => this.$refs.modalChangePassword.show()"
        />
      </template>
      <f-row class="pl-3 pr-3 pb-6">
        <f-col
          class="p-3"
          md="6"
        >
          <strong>{{ $t('labels.firstName') }}: &nbsp;</strong>{{ currentUserInfo.firstName }}
        </f-col>
        <f-col
          class="p-3"
          md="6"
        >
          <strong>{{ $t('labels.lastName') }}: &nbsp;</strong>{{ currentUserInfo.lastName }}
        </f-col>
        <f-col
          class="p-3"
          md="6"
        >
          <strong>{{ $t('labels.email') }}: &nbsp;</strong>{{ currentUserInfo.email }}
        </f-col>
        <f-col
          class="p-3"
          md="6"
        >
          <strong>{{ $t('labels.phone') }}: &nbsp;</strong>{{ currentUserInfo.phone }}
        </f-col>
      </f-row>
    </f-card>
    <f-modal
      id="modalUserUpdate"
      ref="modalUserUpdate"
      @submit="updateUser"
    >
      <modal-user-update :data="userInfo" />
    </f-modal>
    <f-modal
      id="modalChangePassword"
      ref="modalChangePassword"
      @submit="userPasswordChange"
    >
      <modal-change-password :data="passwordInfo" />
    </f-modal>
  </div>
</template>

<script>
import ModalUserUpdate from './ModalUserUpdate.vue'
import ModalChangePassword from './ModalChangePassword.vue'

export default {
  components: {
    ModalUserUpdate,
    ModalChangePassword
  },
  data() {
    return {
      userInfo: {},
      passwordInfo: {},
      currentUserInfo: {}
    }
  },
  async mounted() {
    this.getUserInfo()
  },
  methods: {
    async userPasswordChange() {
      if (
        this.passwordInfo.newPassword === this.passwordInfo.newPasswordAgain
      ) {
        const mutatePasswordInfo = {
          newPassword: this.passwordInfo.newPassword,
          currentPassword: this.passwordInfo.oldPassword
        }

        await this.$http.put('/users/current/password', mutatePasswordInfo)
        this.$flex.notification.success(
          this.$t('messages.changePasswordSuccess')
        )
      } else {
        this.$flex.notification.error(this.$t('messages.changePasswordError'))
      }

      this.$refs.modalChangePassword.hide()
      this.$store.dispatch('logout')
    },
    async getUserInfo() {
      this.currentUserInfo = await this.$http.get('/users/current')
    },
    async updateUser() {
      await this.$http.put('/users/current/update', this.userInfo)

      if (this.currentUserInfo.language !== this.userInfo.language) {
        this.$store.dispatch('changeLanguage', this.userInfo)
      }

      this.$refs.modalUserUpdate.hide()
      this.getUserInfo()
    },
    updateModal() {
      this.userInfo = {
        firstName: this.currentUserInfo.firstName,
        lastName: this.currentUserInfo.lastName,
        phone: this.currentUserInfo.phone,
        language: this.currentUserInfo.language
      }
      this.$refs.modalUserUpdate.show()
    }
  }
}
</script>
