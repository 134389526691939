<template>
  <f-row>
    <f-col md="6">
      <f-input
        :label="$t('labels.phone')"
        phone
        :raw-value.sync="data.phone"
        rules="required"
      />
    </f-col>
    <f-col
      v-if="data.language === null"
      md="6"
    >
      <f-select
        v-model="data.language"
        cols-md="12"
        :label="$t('labels.language')"
        :options="languages"
        rules="required"
        value-type="string"
      />
    </f-col>
  </f-row>
</template>

<script>
import { languages } from '@/data/lists'

export default {
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return { languages }
  }
}
</script>
